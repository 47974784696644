<template>
  <div class="main-container">
    <div class="side-header">
      <div class="name">日常数据</div>
    </div>
    <div class="main-content">
      <div class="top flex flex-between">
        <div class="TodayData">
          <div class="title">今日数据</div>
          <div class="update-time">更新与：{{ time }}</div>
          <div class="task flex flex-center">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column prop="year" label="届次" width="180"></el-table-column>
              <el-table-column prop="classCount" label="班级数" width="180"></el-table-column>
              <el-table-column prop="studentCount" label="总人数"></el-table-column>
              <el-table-column prop="studyClassCount" label="已上课班级"></el-table-column>
              <el-table-column prop="studyStudentCount" label="已上课学生"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <!-- <div class="bottom">
        <div class="historical-trend">
          <div class="title">历史趋势</div>
          <div class="trend-form">
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="统计指标">
                <el-select v-model="form.indicator" placeholder="请选择指标" @change="getSchoolAmountList">
                  <el-option label="购课人数" value="shanghai"></el-option>
                  <el-option label="订单数" value="beijing"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="日期选择">
                <el-date-picker
                  type="date"
                  placeholder="选择日期"
                  v-model="form.date1"
                  @change="drawLine"
                ></el-date-picker>
              </el-form-item>
            </el-form>
          </div>
          <div id="myChart" :style="{ width: '100%', height: '300px' }"></div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      time: '',
      statistics: {},
      target: {},
      form: {
        indicator: "", //指标参数
      },
      tableData:[],
    };
  },
  created() {
    this.getSchoolAmountList();
    this.getSchoolStatisticsInfo()
  },
  methods: {
    getSchoolStatisticsInfo(){
      this.$http
      .get('/api/web/company/getSchoolStatisticsInfo')
      .then(res=>{
        this.tableData=res.data.statistics;
      })
    },
    getSchoolAmountList(){
      let date = new Date();
      
      this.$http
      .get('/api/web/company/getSchoolAmountList')
      .then(res=>{
        this.statistics=res.data.statistics;
        // this.drawLine();
        this.time = date.toLocaleTimeString();
      })
    },
    drawLine() {
      let myChart = echarts.init(document.getElementById("myChart"));
      // 绘制图表
      myChart.setOption({
        tooltip: {},
        grid: {
          top: '4%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          boundaryGap: false,
          data: this.statistics.date,
          axisLabel: {
            margin: 20,
          },
          splitLine:{
            show:true,
            color:'#eaeaea',
            lineStyle:{
                type:'dashed'
            }
          }
        },
        
        yAxis: {
          type: "value",
          splitLine:{
            show:true,
            color:'#eaeaea',
            lineStyle:{
                type:'dashed'
            }
          }
        },
        series: [
          {
            name: "指标变化",
            data: this.statistics.data,
            type: "line",
            symbol: "circle",
            symbolSize: 6,
            areaStyle: {
							color: "rgba(78,165,235,0.30)",
            },
						color: "#4ea5eb",
						lineStyle: {
							color: "#84c0f190", //改变折线颜色
						},
          },
        ],
      });
    },
    //获取接口
    getStatisticsInfo() {
      
      this.$http.get("/api/web/company/getStatisticsInfo").then((res) => {
        this.statistics = res.data.statistics;
        this.target = this.statistics.target;
        
      });
    },
  },
};
</script>

<style lang="less" scoped>
.main-container {
  .side-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 68px;
    padding: 0 20px 0 40px;
    background-color: white;

    .name {
      font-size: 16px;
      color: #333;
    }
  }
  /deep/.el-table{
    text-align: center;
    font-weight: 700;
    .el-table__header{
      .el-table__cell{
        text-align: center;
        .cell{
          line-height: 30px;
        }
        &:first-child{
          text-align: left;
        }
      }
    }
    .el-table__row{
      &:last-child{
        .el-table__cell{
          border: none;
        }
      }
      .el-table__cell{
        font-size: 22px;
        color: #333;
        text-align: center;
        &:first-child{
          text-align: left;
        }
      }
      
    }
  }
  .main-content {
    margin-top: 1px;
    .top {
      margin-bottom: 20px;
      .TodayData {
        width: 100%;
        // height: 290px;
        background-color: white;
        padding: 30px 40px 20px;
        position: relative;
        .title {
          font-size: 18px;
          font-weight: 400;
          text-align: left;
          color: #333333;
          margin-bottom: 15px;
        }
        .update-time {
          position: absolute;
          right: 40px;
          top: 35px;
          font-size: 14px;
          text-align: left;
          color: #666666;
        }
        .task {
          /deep/.item {
            width: 185px;
            position: relative;
            text-align: center;
            padding: 20px 0 0;
            &::before {
              content: "";
              display: block;
              position: absolute;
              bottom: 50%;
              right: 0;
              margin-bottom: -30px;
              width: 1px;
              height: 60px;
              background: #eaeaea;
            }
            &:last-child::before {
              display: none;
            }
            .taskTitle {
              font-size: 16px;
              height: 32px;
              line-height: 32px;
              color: #666666;
              .el-link {
                font-size: 16px;
                color: #666666;
              }
            }
            .newNumber {
              font-size: 28px;
              color: #d71a18;
              height: 48px;
              line-height: 48px;
              letter-spacing: 1px;
              margin: 10px 0;
            }
            .black {
              color: #333333;
            }
            .oldNumber {
              font-size: 14px;
              color: #666666;
            }
          }
        }
      }
    }
    .bottom {
      background-color: white;
      .historical-trend {
        padding: 30px 40px 0;
        height: 570px;
        .title {
          font-size: 18px;
          text-align: left;
          color: #333333;
          margin-bottom: 40px;
        }
        /deep/.trend-form {
          .el-form-item__content {
            text-align: left;
          }
        }
      }
    }
  }
}
</style>
